import React, { Suspense, useEffect } from "react";
import { createBrowserHistory } from "history";
import "./App.scss";
import RootWrapper from "wrappers/RootWrapper";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import "antd/dist/antd.min.css";

export const history = createBrowserHistory();
const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000, // cache for 1 day
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={client}>
      <BrowserRouter>
        <Suspense fallback={null}>
          <RootWrapper />
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
