// import { setResourceI18n } from "helper/i18n";
import { setResourceI18n } from "helper/i18n";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Route, Routes } from "react-router-dom";
import AuthWrapper from "wrappers/AuthWrapper";

const Login = lazy(() => import("pages/Login"));
// const SignUp = lazy(() => import('pages/SignUp'));

export default function AppWrapper() {
  // const { data: listLanguages } = useQuery("listLanguages", getListLanguages);

  const [ready, setReady] = useState(false);
  useEffect(() => {
    setResourceI18n().then(() => {
      setReady(true);
    });
  }, []);
  if (!ready) return null;
  return (
    <div className="root-wrapper">
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/sign-up" exact component={SignUp} /> */}
        <Route path="/*" element={<AuthWrapper />} />
      </Routes>
    </div>
  );
}
