export enum PERMISSIONS {
  RESOURCE_MANAGEMENT = 1,
  LANGUAGE_MANAGEMENT = 2,
  MEMBER_MANAGEMENT = 3,
  RESTAURANT_MANAGEMENT = 4,
  NOTIFICATION_MANAGEMENT = 5,
  USER_MANAGEMENT = 6,
  ROLE_MANAGEMENT = 7,
}

export enum IsSystem {
  Yes = 1,
  No = 0,
}

export enum IsVisible {
  Yes = 1,
  No = 0,
}

export enum COMMON_STATUS {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum LANGUAGE {
  JA = "ja",
  EN = "en",
}

export enum LOGIN_TYPE {
  EMAIL = 1,
  GOOGLE = 2,
  TWITTER = 3,
}

export enum IS_SUBSCRIPTION {
  NO = 0,
  YES = 1,
}

export enum IS_REPLIED {
  NO = 0,
  YES = 1,
}
