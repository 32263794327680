import configs from "config";
import { message } from "antd";
import CustomNotification from "components/CustomNotification";
import i18n from "i18next";
import { LOGIN_TYPE } from "common/enum";
import moment from "moment";

export const handleErrorMessage = (error: any) => {
  CustomNotification({
    type: "error",
    message: i18n.t("common.error"),
    description: getErrorMessage(error),
  });
  if (configs.APP_ENV !== "prod") {
    // tslint:disable-next-line: no-console
    console.log(error);
  }
};

export const getErrorMessage = (error: any) => {
  return error?.response?.data?.errorMessage || "Something went wrong!";
};

export const assignDomain = (url: string) => {
  if (url?.startsWith("http")) return url;
  return `${configs.AWS_DOMAIN}/${url}`;
};

export const getBase64 = (img: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.readAsDataURL(img);
  });

export const getDataFile = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.readAsText(file);
  });

export const formatPriceToJpString = (price: number) =>
  price?.toLocaleString("ja-JP");

export const getTypeLogin = (
  type: LOGIN_TYPE.EMAIL | LOGIN_TYPE.GOOGLE | LOGIN_TYPE.TWITTER
) => {
  if (type === LOGIN_TYPE.EMAIL) {
    return i18n.t("option.login_type_email");
  }
  if (type === LOGIN_TYPE.GOOGLE) {
    return i18n.t("option.login_type_google");
  }
  if (type === LOGIN_TYPE.TWITTER) {
    return i18n.t("option.login_type_twitter");
  }
};

export const convertDayToSend = (day: moment.Moment) => {
  const startTimeDay = moment(day).startOf("day");
  const endTimeDay = moment(day).endOf("day");
  return {
    startAt: startTimeDay.toISOString(),
    endAt: endTimeDay.toISOString(),
  };
};
