import React, { useState } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { Menu, Dropdown, Image, Button, Avatar } from "antd";
import { useQuery, useQueryClient } from "react-query";
import { assignDomain } from "helper";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { DownOutlined } from "@ant-design/icons";
import logo from "assets/images/logo.png";

import avatarDefault from "assets/images/avatar.png";
import { getListLanguage } from "api/language";

export default function PageHeader() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [imgUrl, setImgUrl] = useState("");
  const queryClient = useQueryClient();
  const data: any = queryClient.getQueryData("profile");
  const profile = data?.data;
  const { data: listLanguages } = useQuery("listLanguages", () =>
    getListLanguage()
  );
  const listLanguagesCopy: any = [
    { code: "vi", name: "Vietnamese" },
    { code: "ja", name: "Japanese" },
  ];

  // let img = '';
  // listLanguagesCopy?.data.forEach((language: any) => {
  //   if (language.code === i18next.language) {
  //     img = language.flagIcon;
  //   }
  // });
  const getListLanguageRender = () => {
    const listDataLanguageMap = listLanguages?.data.map(
      (language: any, index: any) => {
        return (
          <Menu.Item
            key={index}
            onClick={() => {
              i18next.changeLanguage(language.code);
            }}
          >
            {language.name}
          </Menu.Item>
        );
      }
    );
    return (
      <Menu style={{ minWidth: 100 }}>
        {listDataLanguageMap}
        <Menu.Item
          onClick={() => {
            i18next.changeLanguage("cimode");
          }}
        >
          {`Show key`}
        </Menu.Item>
      </Menu>
    );
  };

  const handleLogout = () => {
    Cookies.remove("token");
    Cookies.remove("refreshToken");
    navigate("/login");
  };

  const menu = (
    <Menu style={{ minWidth: 200 }}>
      <Menu.Item
        key="1"
        onClick={() => {
          navigate("/accounts/profile");
        }}
      >
        {t("nav.profile")}
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          navigate("/accounts/change-password");
        }}
      >
        {t("nav.change_password")}
      </Menu.Item>
      <Menu.Item key="3" onClick={handleLogout}>
        {t("nav.logout")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.menuWrapper}>
        <Link className={styles.logo} to="/">
          <img src={logo} className={styles.logo__img} />
          {/* <Logo /> */}
        </Link>

        <div className={styles.menuItem}>
          {process.env.REACT_APP_ENV !== "dev" ? null : (
            <div className="mr-16">
              {/* <Image src={img} preview={false} /> */}
              &nbsp;
              <Dropdown
                overlay={() => getListLanguageRender()}
                trigger={["click"]}
              >
                <Button>
                  {t("title.language")}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          )}
          <Dropdown overlay={menu} trigger={["click"]}>
            <div>
              <span>{`Hi ${
                profile?.fullName || profile?.username || "Admin"
              }!`}</span>
              &nbsp;
              <Avatar src={avatarDefault} size={40} />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
