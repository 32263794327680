import { handleErrorMessage } from "./index";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import vi from "locales/vi.json";
import ja from "locales/ja.json";
import { getListLanguageByEnvironment } from "api/language";

const optionInit =
  process.env.REACT_APP_ENV !== "dev" ? { lng: "ja" } : { fallbackLng: "ja" };

i18n.use(initReactI18next).init({
  ...optionInit,
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    vi: { translation: vi },
    ja: { translation: ja },
  },
});

export const setResourceI18n = async () => {
  try {
    const { data } = await getListLanguageByEnvironment({
      environment: "CMS",
    });
    const listLanguage = Object.keys(data);
    listLanguage.forEach((language: any) => {
      i18n.addResourceBundle(
        language,
        "translation",
        data[language],
        true,
        true
      );
    });
  } catch (error) {
    handleErrorMessage(error);
  }
};
