import { sendPost, sendPut } from "./axios";

// eslint-disable-next-line import/prefer-default-export
export const getListLanguage = () => sendPost("/cms/language/list");

export const getListLanguageKey = (payload: any) =>
  sendPost("/cms/language/list-language-key", payload);

export const updateLanguageKey = (payload: {
  key: string;
  environment: string;
  translations: { code: string; value: string }[];
}) => sendPut("/cms/language/update-language-key", payload);

export const addLanguageKey = (payload: {
  key: string;
  defaultValue: string;
  environment: string;
  translations: { code: string; value: string }[];
}) => sendPut("/cms/language/add-language-key", payload);

export const getListEnvironment = () =>
  sendPost("/cms/language/list-environments");

export const getListLanguageByEnvironment = (payload: {
  environment: "CMS" | "SERVER" | "APP";
  code?: string;
}) => sendPost("/cms/language/get-file-language", payload);

export const uploadFileLanguage = (payload: {
  environment: string;
  code: string;
  languages: any;
}) => sendPost("/cms/language/upload-file-language", payload);
