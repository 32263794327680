import React, { lazy, Suspense, useMemo } from "react";
import Cookies from "js-cookie";
import PageHeader from "components/PageHeader";
import SideNav from "components/SideNav";
import { Routes, Route, Navigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { useQuery } from "react-query";
import { getProfile } from "api/authentication";
import { Spin } from "antd";
import { getListRoles } from "api/role";

const Dashboard = lazy(() => import("pages/Dashboard"));
const Staffs = lazy(() => import("pages/Systems/Staff"));
const Users = lazy(() => import("pages/Users"));
const Notification = lazy(() => import("pages/Notification"));
const Contact = lazy(() => import("pages/Contact"));

const Profile = lazy(() => import("pages/Profile/ChangeProfile"));
const Password = lazy(() => import("pages/Profile/ChangePassword"));
const Language = lazy(() => import("pages/Systems/Languages"));
const Configs = lazy(() => import("pages/Configs"));
const Price = lazy(() => import("pages/Systems/Price"));

export default function PageWrapper() {
  const isAuthenticated = !!Cookies.get("token");
  const { data: profile } = useQuery("profile", getProfile, {
    enabled: isAuthenticated,
  });

  const isManagerUser = useMemo(() => {
    return profile?.data?.role?.roleName === "MANAGE_USER";
  }, [profile]);

  if (!isAuthenticated) return <Navigate to="/login" />;
  if (!profile) return null;
  return (
    <div className={styles.pageWrapper}>
      <PageHeader />
      <div className={styles.mainWrapper}>
        <SideNav />
        <div className={styles.pageContent}>
          <Suspense
            fallback={
              <div className="spin">
                <Spin />
              </div>
            }
          >
            <Routes>
              {!isManagerUser ? (
                <Route path="/" element={<Dashboard />} />
              ) : (
                <Route path="/" element={<Users />} />
              )}
              <Route path="/users" element={<Users />} />
              <Route path="/contacts" element={<Contact />} />
              <Route path="/accounts/profile" element={<Profile />} />
              {!isManagerUser && (
                <>
                  <Route path="/notification" element={<Notification />} />
                  <Route path="/accounts" element={<Staffs />} />
                  <Route
                    path="/accounts/change-password"
                    element={<Password />}
                  />
                  <Route path="/systems/languages" element={<Language />} />
                  <Route path="/systems/price" element={<Price />} />
                  <Route path="/systems/configs" element={<Configs />} />
                </>
              )}
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
}
