import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import {
  MailOutlined,
  SettingOutlined,
  MoneyCollectOutlined,
  UserOutlined,
  UserSwitchOutlined,
  AppstoreOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import Sider from "antd/lib/layout/Sider";
import { useQueryClient } from "react-query";

const { SubMenu } = Menu;

export default function SideNav() {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => setCollapsed(!collapsed);
  const [selectedKey, setSelectedKey] = useState("1");
  const SIDE_NAV_WIDTH = 220;
  const queryClient = useQueryClient();

  const profile: any = queryClient.getQueryData("profile");

  const isManagerUser = useMemo(() => {
    return profile?.data?.role?.roleName === "MANAGE_USER";
  }, [profile]);

  const routes = [
    {
      key: "1",
      text: t("nav.dashboard"),
      url: "/",
      icon: <AppstoreOutlined />,
      visible: !isManagerUser,
    },
    {
      key: "2",
      text: t("nav.users"),
      url: "/users",
      icon: <UserOutlined />,
      visible: true,
    },
    // {
    //   key: "3",
    //   text: t("nav.news"),
    //   url: "/news",
    //   icon: <FileTextOutlined />,
    //   visible: true,
    // },
    // {
    //   key: "4",
    //   text: t("nav.payment"),
    //   url: "/payments",
    //   icon: <CreditCardOutlined />,
    //   visible: true,
    // },
    {
      key: "5",
      text: t("nav.contact"),
      url: "/contacts",
      icon: <MailOutlined />,
      visible: true,
    },
    {
      key: "6",
      text: t("nav.notifications"),
      url: "/notification",
      icon: <NotificationOutlined />,
      visible: !isManagerUser,
    },

    {
      key: "7",
      text: t("nav.system"),
      icon: <UserSwitchOutlined />,
      visible: !isManagerUser,
      children: [
        {
          key: "7.1",
          text: t("nav.staff"),
          url: "/accounts",
          icon: <UserOutlined />,
          visible: true,
        },
        {
          key: "7.2",
          text: t("nav.configs_system"),
          url: "/systems/configs",
          icon: <SettingOutlined />,
          visible: true,
        },
        {
          key: "7.3",
          text: t("nav.price"),
          url: "/systems/price",
          icon: <MoneyCollectOutlined />,
          visible: true,
        },
      ],
    },
    // {
    //   key: "8",
    //   text: t("nav.config"),
    //   icon: <SettingOutlined />,
    //   children: [

    //   ],
    // },
  ];

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname.startsWith(route.url || "###")) {
        setSelectedKey(route.key);
      }
      if (route.children) {
        route.children.forEach((childRoute) => {
          if (location.pathname.startsWith(childRoute.url || "###")) {
            setSelectedKey(childRoute.key);
          }
        });
      }
    });
  }, [location.pathname]);

  return (
    <div className={styles.side_nav}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapsed}
        width={SIDE_NAV_WIDTH}
        className={`${styles.side_nav__sider} scroll`}
      >
        <Menu
          selectedKeys={[selectedKey]}
          defaultOpenKeys={["3"]}
          mode="inline"
          inlineCollapsed={collapsed}
          style={{
            width: collapsed ? 80 : SIDE_NAV_WIDTH,
            transition: "width 0.3s",
          }}
        >
          {routes.map((route) => {
            if (route.visible === false) {
              return null;
            }
            if (route.children) {
              return (
                <SubMenu key={route.key} icon={route.icon} title={route.text}>
                  {route.children?.map((childRoute) => {
                    if (childRoute.visible === false) {
                      return null;
                    }
                    return (
                      <Menu.Item key={childRoute.key} icon={childRoute.icon}>
                        <Link to={childRoute.url}>{childRoute.text}</Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
            return (
              <Menu.Item key={route.key} icon={route.icon}>
                <Link to={route.url}>{route.text}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
    </div>
  );
}
